/*
 |-----------------------------------------------------------------------------
 | components/molecules/Navbar/Navbar.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';

import Container from '@/layouts/Container';
import HeaderMenu from '@/molecules/HeaderMenu';
import { menuSelector } from '@/features/menu';
import { useAppSelector } from '@/app/hooks';

import * as INavbar from './types';

const Navbar: FC<INavbar.IProps> = ({
	darkMode,
	menu,
	storybook,
	utilities,
}) => {
	// ray('Debug molecule Navbar:', {
	// 	darkMode: darkMode,
	// 	menu: menu,
	// 	utilities: utilities,
	// }).blue();

	const menuState = useAppSelector(menuSelector);

	return (
		<nav
			className={`
				md:bg-brand-1
				md:fixed
				md:bottom-0
				md:left-0
				md:right-0
				md:top-[128px]
				md:transition-opacity
				custom:top-[110px]
				xs:top-[108px]
				${menuState || storybook ? 'md:opacity-100' : 'md:opacity-0 md:pointer-events-none'}
				${utilities ? utilities : null}
			`}
			itemScope
			itemType="http://schema.org/SiteNavigationElement"
		>
			<Container>
				{menu && (
					<HeaderMenu
						darkMode={darkMode}
						items={get(menu, 'items')}
						label={get(menu, 'label')}
					/>
				)}
			</Container>
		</nav>
	);
};

export default Navbar;
