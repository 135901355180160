/*
 |-----------------------------------------------------------------------------
 | components/molecules/HeaderMenu/HeaderMenu.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC, useEffect, useRef } from 'react';

import { forEach, get, map } from 'lodash';
import Link from 'next/link';
// import { ray } from 'node-ray/web';
import { useRouter } from 'next/router';

import Icon from '@/atoms/Icon';

import * as IHeaderMenu from './types';

const HeaderMenu: FC<IHeaderMenu.IProps> = ({
	darkMode,
	items,
	label,
	utilities,
}) => {
	// ray('Debug molecule HeaderMenu:', {
	// 	darkMode: darkMode,
	// 	items: items,
	// 	label: label,
	// 	utilities: utilities,
	// }).blue();

	const router = useRouter();

	const dropdownToggles = useRef([]);

	useEffect(() => {
		const menu = document.querySelector('#sub-menu');

		menu.classList.add('hidden');
		menu.previousSibling.setAttribute('title', 'Click to open');
	}, [router.asPath]);

	const classNamesLvl0 = `
		text-2xl
		transition-color
		md:text-4xl
		custom:text-3xl
		xs:text-2xl
		${
			darkMode
				? 'text-brand-3 hover:text-brand-4'
				: 'text-brand-3 hover:text-brand-4'
		}
	`;

	const classNamesLvl1 = `
		text-xl
		transition-color
		md:text-4xl
		custom:text-3xl
		xs:text-2xl
		${
			darkMode
				? 'text-brand-4 hover:text-brand-6'
				: 'text-brand-4 hover:text-brand-6'
		}
	`;

	const externalLink = (label, url, utilities) => {
		return (
			<a
				className={`group ${utilities}`}
				href={url}
				rel="external noopener noreferrer"
				target="_blank"
			>
				{label}

				<Icon
					id="r-link"
					utilities={`
						fill-current
						h-3.5
						inline-block
						ml-2
						opacity-25
						transition-opacity
						w-3.5
						group-hover:opacity-100
					`}
				/>
			</a>
		);
	};

	const internalLink = (label, path, utilities) => {
		return (
			<Link href={path || '#'} legacyBehavior passHref>
				<a
					className={`
						${get(router, 'asPath') === path ? `cursor-default text-black` : ''}
						${utilities}
					`}
					href="jsx-a11y"
				>
					{label}
				</a>
			</Link>
		);
	};

	const toggleDropdown = index => {
		const thisButton = get(dropdownToggles, `current[${index}]`);

		forEach(get(dropdownToggles, 'current'), toggle => {
			if (toggle) {
				if (toggle === thisButton) {
					thisButton.nextSibling.classList.toggle('hidden');

					switch (thisButton.getAttribute('title')) {
						case 'Click to close':
							thisButton.setAttribute('title', 'Click to open');
							thisButton.classList.add('text-brand-3');
							thisButton.classList.remove(
								'text-brand-4',
								'md:after:bg-brand-4',
								'md:after:block',
								'md:after:content-[""]',
								'md:after:h-px',
								'md:after:mx-8',
								'md:after:w-16'
							);
							break;
						case 'Click to open':
							thisButton.setAttribute('title', 'Click to close');
							thisButton.classList.add(
								'text-brand-4',
								'md:after:bg-brand-4',
								'md:after:block',
								'md:after:content-[""]',
								'md:after:h-px',
								'md:after:mx-8',
								'md:after:w-16'
							);
							thisButton.classList.remove('text-brand-3');
							break;
					}
				} else {
					toggle.nextSibling.classList.toggle('hidden');
					toggle.setAttribute('title', 'Click to open');
				}
			}
		});
	};

	return (
		<ul
			className={`
				flex
				font-normal
				relative
				space-x-8
				md:block
				md:space-y-4
				md:space-x-0
				${utilities ? utilities : ''}
			`}
			aria-label={label}
			data-testid="headerMenu"
		>
			{map(items, (item, index) => (
				<li
					key={`header-menu-item-${index}`}
					className={`md:w-[228px] custom:w-full`}
				>
					{get(item, 'url') ? (
						externalLink(
							get(item, 'label'),
							get(item, 'url'),
							classNamesLvl0
						)
					) : get(item, 'children') ? (
						<>
							<button
								className={`
									flex
									items-center
									justify-between
									w-full
									custom:after:hidden
									${classNamesLvl0}
								`}
								onClick={() => toggleDropdown(index)}
								ref={el =>
									(dropdownToggles.current[index] = el)
								}
								title="Click to open"
							>
								{get(item, 'label')}
							</button>
							<ul
								id="sub-menu"
								className={`
								absolute
								bg-brand-1
								hidden
								-ml-4
								p-4
								space-y-4
								md:bg-transparent
								md:left-[228px]
								md:m-0
								md:p-0
								md:top-0
								custom:border-b
								custom-border-b-brand-4
								custom-border-b-solid
								custom:py-4
								custom:space-y-2
								custom:static
							`}
							>
								{map(get(item, 'children'), (child, i) => (
									<li
										key={`header-menu-item-${index}__child-item-${i}`}
									>
										{get(child, 'newTab')
											? externalLink(
													get(child, 'label'),
													get(child, 'url'),
													classNamesLvl1
												)
											: internalLink(
													get(child, 'label'),
													get(child, 'path'),
													classNamesLvl1
												)}
									</li>
								))}
							</ul>
						</>
					) : (
						internalLink(
							get(item, 'label'),
							get(item, 'path'),
							classNamesLvl0
						)
					)}
				</li>
			))}
		</ul>
	);
};

export default HeaderMenu;
